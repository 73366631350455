/* Estilo para los botones de navegación */
.news-slider .nav-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  /* Estilo para los botones de navegación activos */
  .news-slider .nav-button.active {
    background-color: #0056b3;
  }
  
    #aboutBlock {
  }

  #aboutBlock a {
    text-decoration: none;
  }
  
    #aboutBlock .card-text {
    font-size: 12px;
    font-weight: bold;
  }
  
    #aboutBlock .card {
    padding: 0;
    border-radius: 8px;
    overflow: auto;
    box-shadow: -1px -1px 0px var(--light-color);
  }

    #aboutBlock .card-body {
    padding: 0;
  }

    #aboutBlock .card-title {
    position: relative;
    top: 0px;
    font-size: 16px;
    text-align: left;
    padding: 16px 16px 8px;
  }

    #aboutBlock p.card-text {
    top: 43px;
    z-index: 99999;
    left: 0;
    padding: 8px 16px 32px;
    opacity: 0.75;
  }

    #aboutBlock .imgNews {
    position: relative;
    width: 80%;
    height: auto;
  }