/* Estilo para los botones de navegación */
.news-slider .nav-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  /* Estilo para los botones de navegación activos */
  .news-slider .nav-button.active {
    background-color: #0056b3;
  }
  
  #newsBlock {
    background-color: var(--subtle-color);
  }
  
  #newsBlock .card-text {
    font-size: 12px;
    font-weight: bold;
  }
  
  #newsBlock .card {
    padding: 0;
    border-radius: 8px;
    overflow: auto;
    box-shadow: -1px -1px 0px var(--light-color);
  }

  #newsBlock .card-body {
    padding: 0;
  }

  #newsBlock .card-title {
    position: absolute;
    top: 0px;
    z-index: 99999;
    left: 0;
    background-color: var(--tertiary-color);
    color: var(--primary-color);
    width: 100%;
    font-size: 16px;
    text-align: left;
    padding: 16px 16px 8px;
    opacity: 0.75;
  }

  #newsBlock p.card-text {
    position: absolute;
    background-color: var(--tertiary-color);
    top: 43px;
    z-index: 99999;
    left: 0;
    padding: 8px 16px 32px;
    opacity: 0.75;
  }

  #newsBlock .imgNews {
    position: relative;
    top:0;
    width: 100%;
    height: 100%;
  }