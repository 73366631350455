@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

:root {
  --bs-body-font-family: "Inter", sans-serif !important;
  --primary-color: #C8102E;
  --secondary-color: #002244;
  --tertiary-color: #FFD700;
  --dark-color: #002244;
  --sucess-color: #FFD700;
  --text-color: #333333;
  --subtle-color: #EFEFFD;
  --accent-color: #FAFCFE;
  --light-color: #F5F5F5;
  --white: #FFFFFF;
  --spacer-one: 10px;
  --spacer-two: 14px;
  --spacer-three: 20px;
  --spacer-four: 40px;
  --spacer-five: 64px;
  --border-radius-one: 4px;
  --border-radius-two: 8px;
}

#root {
  /* padding: 0;
  background: none;
  background-color: white; */
  background-color: var(--light-color);
}

html {
  /*background: black;*/
}

/* Body Styles */
body {
  font-family: var(--text-color);
  background-color: var(--light-color);
  font-size: 16px;
  letter-spacing: -0.5px;
}


a {
  text-decoration: none;
  color: inherit;
}

html h1 {
    font-size: 64px;
    font-weight: bold;
    letter-spacing: -4px;
}

html h2{
    font-size: 40px;
    font-weight: bold;
    letter-spacing: -2.5px;
}

html h3{
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.2px;
}

html h4 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1.2px;
}

html h5 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -1.2px;
}

body hr {
  border-top: 1px #f3f3f3 solid;
  opacity: 0.75;
}

html .btn-primary {
  background-color: var(--secondary-color);
  box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.10), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset;
  border: 0;
  padding: 12px 24px;
}

.b-radius-sm{
  border-radius: var(--border-radius-one);
}

.b-radius-md{
  border-radius: var(--border-radius-two);
}

/* Truncate Text */
.two-line-truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/*Nav Styles */
#mainNav {
    background-color: var(--secondary-color);
    height: 60px;
    padding: 0;

    .navbar-toggler{
      top: -10px;
    }

    .container-fluid{
      margin: 0;
      padding: 0;
    }
}

#logo{
    background: url(../assets/img/AlexAce-Logo.svg);
    background-repeat: no-repeat;
    width: 82px;
    height: 77px;
    position: relative;
    text-indent: -9999px;
    top: 16px;
    z-index: 9999;
    margin: 0 0 -16px 16px;
}

#navbarNavDropdown{
  background: var(--secondary-color);
  position: relative;
  z-index: 9998;

  @media (max-width: 992px){
    top: -8px;
  }

  ul li {
    text-align: center;
    color: var(--white);
  }
}

#heroe {
  height: 535px;
  background: var(--secondary-color);
  display: flex;
  flex-wrap: wrap;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
}

#heroe h1{
  color: var(--light-color);
  font-weight:bolder;
  font-size: 64px;
}

#heroe::after{
  content: '';
  background-image: url("../../public/assets/img/heroe.png");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

#heroe .callButton {
  display: flex;
  background: var(--primary-color);
  max-width: 320px;
  height: 106px;
  padding: 12px 24px;
  border-radius: 8px;
  color: var(--light-color);
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
}

footer {
  background: var(--tertiary-color);
}

#footerMenu {
  li{
    list-style: none;
  }

  li a{
    margin: 0 8px;
  }
}

#socialMedia a{
  width: 32px;
  height: 32px;
  margin: 4px;
}

/* Format for cards*/
html .card {
  padding: var(--spacer-four);
  border: 0;
  background-color: var(--white);

  @media (max-width: 567px){
    padding: var(--spacer-one);
  }
}

html .card .card-body{
  background-color: var(--white);
}

html .card h1, html .card h2  {
  font-size: var(--spacer-five);
  font-weight: 900;
  letter-spacing: -2.7px;
  text-align: center;
}

html .card h3 {
  font-size: var(--spacer-four);
  font-weight: bold;
  letter-spacing: -2.5px;
  text-align: center;
}

html .card h4 {
  font-size: var(--spacer-four);
  font-weight: bold;
  letter-spacing: -1px;
  text-align: center;
}

.matches .textDate{
  font-size: 14px;
  font-weight: 500px;
}

.matches .gameBar {
  padding: 10px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background-color:  var(--light-color);
  box-sizing: border-box;
}

.matches .numberPrediction{
  background-color: var(--tertiary-color);
  padding: 10px 4px;
  text-align: center;
}

#predictions.card {
  height: 100%;
}

#membershipHome li{
  list-style: none;
}

#testimonials img, #about img {
  position: relative;
  margin-top: -112px;
  margin-left: -36px;

  @media (max-width: 567px){
    margin-top: -50px;
    margin-left: inherit;
    width: 60px;
    height: auto;
  }
}

.card-grey{
  background-color: var(--subtle-color);
  border-radius: var(--border-radius-two);
}

.card-yellow{
  background-color: var(--tertiary-color);
  
}

/* Styles for Slider Scroller */
.scroll-container {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
  -ms-overflow-style: none; /* Oculta la barra de desplazamiento en Internet Explorer y Edge */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Oculta la barra de desplazamiento en Chrome y Safari */
}

/*Blog */

#mainBlog img, #otherBlogs img {
  max-width: 530px;
}

#logoAbout{
  background: url('../assets/img/AlexAce-Logo.svg');
  background-repeat: no-repeat;
  width: 329px;
  height: 304px;
  position: relative;
  text-indent: -9999px;
  top: 16px;
  margin: 0 0 -16px 16px;
}

.highBox {
  background: rgb(254,235,128);
  background: linear-gradient(45deg, rgba(254,235,128,1) 0%, rgba(255,213,20,1) 100%);
}

.sliderButton{
  position: relative;
  top: -50px;
}

#Login .redButton {
  background-color:  var(--primary-color);
  color: white;
}
